<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <i class="fas fa-book-reader mg-r-5 mg-l-15"></i> R&Eacute;SUM&Eacute;
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button
        iconClass="fas fa-highlighter"
        class="mr-1"
        (onClick)="printEditionConditionnement()"
        tooltip="Générer le bon de conditionnement">
      </yo-button>

      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [width]="800"></yo-help>
    </div>
  </dxi-item>
</dx-toolbar>

<div class="calcul-state">
  <span>{{ calculationState }}</span>
</div>

<!--<dx-data-grid
  id="tab-calcul-conditionnement-details"
  [dataSource]="headersResults"
  keyExpr="id"
  width="100%"
  columnResizingMode="widget"
  [allowColumnReordering]="true"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  (onCellPrepared)="onCellPrepared($event)"
  (onRowPrepared)="onRowPrepared($event)"
  (onRowClick)="loadChildren($event)"
  [noDataText]="NoDataText"
  #gridHeaders>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="ficheTechniqueLabel" caption="Plat" [width]="100"
              [filterOperations]="['contains']"
              [allowGrouping]="true"
              [autoExpandGroup]="true"
              groupCellTemplate="groupingPlatTemplate"
              [groupIndex]="1">
  </dxi-column>
  <dxi-column dataField="atelierLabel" caption="Atelier" [width]="'auto'"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="equipeLabel" caption="Equipe" [width]="'auto'"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="pointDeLivraison?.libelle" caption="Point livraison client" [width]="'auto'"
              [filterOperations]="['contains']" cellTemplate="plcTemplate">
  </dxi-column>
  <dxi-column dataField="prestationLabel" caption="Prestation" [width]="'auto'"
              [filterOperations]="['contains']" cellTemplate="prestaTemplate">
  </dxi-column>
  <dxi-column dataField="declinaisonLabel" caption="Déclinaison" [width]="'auto'"
              [filterOperations]="['contains']" cellTemplate="decliTemplate">
  </dxi-column>
  <dxi-column dataField="regimeLabel" caption="Régime" [width]="'auto'"
              [filterOperations]="['contains']" cellTemplate="regimeTemplate">
  </dxi-column>
  <dxi-column dataField="nbPiecesCalculated" caption="Nombre pièce(s)" cellTemplate="nbPiecesTemplate" [width]="100" [filterOperations]="['contains']"></dxi-column>
  <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="'auto'"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="varianteLabel" caption="Variante" [width]="'auto'"
              [filterOperations]="['contains']">
  </dxi-column>
  <dxi-column dataField="queueUc" caption="Reste" [width]="100" cellTemplate="resteTemplate"
              [filterOperations]="['contains']">
  </dxi-column>

  <dxi-column dataField="udpLibelle" caption="Unité de production" [width]="'auto'"
              [filterOperations]="['contains']"
              [allowGrouping]="true"
              [groupIndex]="0">
  </dxi-column>
  <dxi-column dataField="dateConditionnement" caption="Date conditionnement" [customizeText]="customizeDateValue" [width]="'auto'"
              [filterOperations]="['contains']">
  </dxi-column>

  <dxi-column dataField="dateFabrication" caption="Date fabrication" [width]="'auto'"
              [filterOperations]="['contains']">
  </dxi-column>

  <div *dxTemplate="let cell of 'plcTemplate'" class="text-center">
    {{cell.value}} <span *ngIf="cell.row.data.pointLivraisonRegroupe && !cell.row.data.reste"><i class="fa-solid fa-layer-group reste"></i></span>
  </div>

  <div *dxTemplate="let cell of 'decliTemplate'" class="text-center">
    {{cell.value}} <span *ngIf="cell.row.data.declinaisonRegroupee && !cell.row.data.reste"><i class="fa-solid fa-layer-group reste"></i></span>
  </div>

  <div *dxTemplate="let cell of 'prestaTemplate'" class="text-center">
    {{cell.value}} <span *ngIf="cell.row.data.prestationRegroupee && !cell.row.data.reste"><i class="fa-solid fa-layer-group reste"></i></span>
  </div>

  <div *dxTemplate="let cell of 'regimeTemplate'" class="text-center">
    {{cell.value}} <span *ngIf="cell.row.data.regimeRegroupe && !cell.row.data.reste"><i class="fa-solid fa-layer-group reste"></i></span>
  </div>

  <div *dxTemplate="let cell of 'qteConditionneeTemplate'">
    {{cell.value}}
  </div>

  <div *dxTemplate="let cell of 'poidsNetUcTemplate'">
    <span *ngIf="!cell.row.data.reste">{{cell.value}} Kg</span>
  </div>

  <div *dxTemplate="let cell of 'resteTemplate'">
    <span [className]="cell.value ? 'reste' : ''">{{cell.value || ''}} {{cell.value ? 'Kg' : ''}}</span>
  </div>

  <div *dxTemplate="let cell of 'nbCmdTemplate'">
    <span *ngIf="!cell.row.data.reste">{{ cell.value }}</span>
  </div>

  <div *dxTemplate="let cell of 'nbPiecesTemplate'">
    {{cell.value}} {{ cell.value ? cell.row.data.uniteAConditionner : '' }}
  </div>

  <div *dxTemplate="let cell of 'groupingPlatTemplate'">
    {{ cell.value }} (Poids net total = {{ headerPoidsNetTotalByFicheTechnique[cell.value] }} kg, Quantité conditionnée = {{ headerQteConditionneeByFicheTechnique[cell.value] }} dont {{ headerNbRestesByFicheTechnique[cell.value] }} {{ headerNbRestesByFicheTechnique[cell.value] > 1 ? ' restes ' : ' reste '  }})
  </div>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[1, 2, 20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} résumé(s)">
  </dxo-pager>

</dx-data-grid>-->
<!--<div>
  Légende : <div class="legende-rectangle regroupee"></div> Configuration regroupée <div class="legende-rectangle non-regroupee"></div> Configuration non regroupée
  <br/> Remarque : <i>Le(s) plat(s) destiné(s) à de la distribution directe ne s'affichent pas sur cet écran, vous pourrez directement les retrouver dans le bon de livraison</i>
</div>-->

<div class="mg-t-5 mg-l-0" id="wrapper-lots">
  <div [style.width.px]="'90%'">
    <yo-preparation-calcul-conditionnement-details
      [detailsByPoidsConditionnement]="detailsByPoidsConditionnementForParentSelected"
      [idParentSelected]="idParentSelected"></yo-preparation-calcul-conditionnement-details>
  </div>
</div>

<div class="d-flex mg-t-15 text-center" id="footer-step">
  <div class="col-lg-12 text-center">
    <yo-button (onClick)="previousStep()" label="Précédent" tooltip="Plan de production"
               iconClass="fas fa-angle-left"></yo-button>
  </div>
</div>

<dx-popup
  [showTitle]="true"
  title="Erreurs"
  [(visible)]="displayErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <ng-container *ngIf="errors?.plcsWithoutRulesConfiguration?.length">
    <label>Liste des points de livraison client pour lesquels aucune règle de regroupement n'a été créée : </label>
    <dx-data-grid
      [dataSource]="errors?.plcsWithoutRulesConfiguration"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Point de livraison client" cellTemplate="templatePlc" [filterOperations]="['contains']"
                  [allowFiltering]="true"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} point(s) de livraison client">
      </dxo-pager>
      <div *dxTemplate="let cell of 'templatePlc'">
        <a (click)="goToPlcPage(cell.row.data.idClient, cell.row.data.id)"><span
          class="cursor save-color">{{ cell.row.data.libelle }}</span></a>
      </div>
    </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="errors?.implementationsModelesPlatsNotRealized?.length">
    <label>Veuillez contrôler l'existence de l'implémentation d'un modèle de conditionnement plat pour les produits
      suivants : </label>
    <dx-data-grid
      [dataSource]="errors?.implementationsModelesPlatsNotRealized"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Produit" cellTemplate="templateProduit" [filterOperations]="['contains']"
                  [allowFiltering]="true"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} produit(s)">
      </dxo-pager>
      <div *dxTemplate="let cell of 'templateProduit'">
        <a (click)="goToProductPage(cell.row.data.id)"><span
          class="cursor save-color">{{ cell.row.data.libelle }}</span></a>
      </div>
    </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="errors?.contraintesPlcsNotFound?.length">
    <label>Les modèles de plat suivants ne sont pas affectées à un modèle de PLC </label>
    <dx-data-grid
      [dataSource]="errors?.contraintesPlcsNotFound"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Modèle plat / Point de livraison client" cellTemplate="valueToDisplay"
                  [filterOperations]="['contains']" [allowFiltering]="true"></dxi-column>
      <div *dxTemplate="let cell of 'valueToDisplay'">
        {{ cell.row.data }}
      </div>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} information(s)">
      </dxo-pager>
    </dx-data-grid>
  </ng-container>
  <ng-container *ngIf="plcsNotTakenAccount?.length">
    <label>
      Certains points de livraison présents dans le plan de production ne sont pas pris en compte par le calcul du
      conditionnement car
      ces derniers sont absents des modèles points de livraison client :
    </label>
    <dx-data-grid
      [dataSource]="plcsNotTakenAccount"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column caption="Libellé" dataField="libelle" [filterOperations]="['contains']"
                  [allowFiltering]="true"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} point(s) de livraison client">
      </dxo-pager>
    </dx-data-grid>
  </ng-container>
</dx-popup>

<yo-print-settings-editions-conditionnement
  [idPlanProduction]="idPlanProduction"></yo-print-settings-editions-conditionnement>
