// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10877(4ed2c3d246)-C08/11/2024-15:01:42-B08/11/2024-15:06:51' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10877(4ed2c3d246)-C08/11/2024-15:01:42-B08/11/2024-15:06:51",
  branch: "master",
  latestTag: "6.1",
  revCount: "10877",
  shortHash: "4ed2c3d246",
  longHash: "4ed2c3d2462e47303a8758305f37c18c13614f4f",
  dateCommit: "08/11/2024-15:01:42",
  dateBuild: "08/11/2024-15:06:51",
  buildType: "Ansible",
  } ;
